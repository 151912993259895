import React from 'react'
import './Footer.css'
import instagram from '../../assets/instagram.png'
import linkedin from '../../assets/linkedin.png'
import github from '../../assets/github.png'
import logo from '../../assets/logo.png'


const Footer = () => {
  return (
   <div className="footer">
<hr/>
    <div className="social-media">
    <img src={linkedin} alt="" />
    <img src={github} alt="" />
    <img src={instagram} alt="" />

</div>
<div className='logosocial'>
    <img src={logo} alt="" />
</div>

<div className="blur blur-f-1"></div>
<div className="blur blur-f-2"></div>

   </div>
  )
}

export default Footer