import './App.css';
import Hero from './Components/Hero/Hero';
import Programs from './Components/Programs/Programs';
import Raisons from './Components/Raisons/Raisons';
import Plans from './Components/Plans/Plans';
import Testimonials from './Components/Testimonials/Testimonials';
import Join from './Components/Joinus/Join';
import Footer from './Components/Footer/Footer';
function App() {
  return (
    <div className="App">
      <Hero/>
      <Programs/>
      <Raisons/>
      <Plans />
      <Testimonials/>
      <Join/>
      <Footer/>
    </div>
  );
}

export default App;
