import React from 'react'
import './Programs.css'
import rightArrow from '../../assets/rightArrow.png'
import {programsData} from '../../data/programsData'
const Programs = () => {
  return (
    <div id="programs" className="programs">
     

{/*header*/}

<div className="p-header">
<span className='stroke-text'>Explore Our</span>
<span>Programs</span>
<span className='stroke-text'>To shape you</span>
</div>

{/*articles*/}
<div className="programs-category">
{programsData.map((program)=>(

<div className="category">
{program.image}
    <span>{program.heading}</span>
    <span>{program.details}</span>
    <div className="join-us">
        <span>Join now</span><img src={rightArrow} alt="" />
    </div>
</div>

))
}


</div>

    </div>
  )
}

export default Programs