import React from 'react'
import './Join.css'
import { useRef } from 'react'
import emailjs from '@emailjs/browser'

const Join = () => {
    const form=useRef();
    const sendEmail = (e) => {
        e.preventDefault();
    
        emailjs.sendForm('service_60nxbkn', 'template_1a62jix', form.current, 'ctsir2-1AGowCcfuP')
          .then((result) => {
              console.log(result.text);
          }, (error) => {
              console.log(error.text);
          });
        }
  return (
   <div id='join-us' className="join">

    <div className="l-join">
        <hr/>
 <div>    
<span className='stroke-text'>READY TO </span>
<span>LEVEL UP</span>
</div>  

<div>    
<span>YOUR BODY </span>
<span className='stroke-text'>WITH US ?</span>
</div> 

    </div>

    <div className="r-join">
     <form onSubmit={sendEmail} ref={form} className="form-email">
        <input type="text" name="user-email" placeholder='Enter your Email Adress' />
        <button className='button'>Join now</button>
     </form>
    </div>
   </div>
  )
}

export default Join