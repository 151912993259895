import React from 'react'
import './Hero.css' 
import Header from '../Header/Header'
import heroimage from '../../assets/hero_image.png'
import heroback from  '../../assets/hero_image_back.png'
import heart from '../../assets/heart.png'
import calories from '../../assets/calories.png'
import {motion} from 'framer-motion'
import NumberCounter from 'number-counter'

const Hero = () => {
  const transition={type:'spring',duration:3}
  const mobile=window.innerWidth<=768?true:false;
  return (

<div className="hero" id='home'>
<div className="blur blur-b"></div>
    <div className="hero-l">
      <Header />
 {/*the best add*/}
   <div className="the-best-add">

<motion.div initial={{left:mobile?'168px':'239'}}
whileInView={{left:'9px'}}
transition={{...transition,type:'tween'}}
 ></motion.div>
<span>The best fitness Club in the Town</span>
</div>


 {/*HERO TEXT*/}
<div className="hero-text">
<div>
  <span class="stroke-text">SHAPE </span>
  <span>YOUR</span>
</div>

<div>
  <span>IDEAL BODY </span>
</div>
<div>
  <span>In here we will help you to shape and build your ideal body and live up your life to fullest
</span>
</div>

</div>

 {/*Figures*/}
 <div className="figures">
<div>
  <h3><NumberCounter end={140} start={100} delay='4' preFix="+"/></h3>
  <p>EXPERT COACHES</p>
</div>

<div>
    <h3><NumberCounter end={873} start={750} delay='4' preFix="+"/></h3>
     <p>MEMBRES JOINED</p>
</div>

<div>
    <h3><NumberCounter end={50} start={30} delay='4' preFix="+"/></h3>
     <p>FITNESS PROGRAMS</p>
</div>

 </div>

 {/*Buttons */}
 
 <div className='btn-btn' >
  <button className="button">Get Started</button>
  <button className="button-2">Learn More</button>

 </div>





    </div>
    
    <div className="hero-r">

  <button className='button'>Join now</button>
{/* heart rate*/}
  <motion.div 
  transition={transition}
  initial={{right:'-1em'}}
  whileInView={{right:'4em'}}
   className="heart-rate">
    <img src={heart} alt="" />
    <span>Heart rate</span>
    <span>116bpm</span>
  </motion.div>
{/* hero image*/}
  <img src={heroimage} alt="" className='heroimage' />
  <motion.img 
   transition={transition}
   initial={{right:'16em'}}
   whileInView={{right:'19em'}}
    src={heroback} alt="" className='heroback' />

{/* Calories*/}
<motion.div className="calories"
transition={transition}
initial={{right:'30em'}}
whileInView={{right:'27em'}}
>

  <img src={calories} alt="" />
  <div className="calotext">
    <span> Calories burned </span>
    <span>220 Kcal</span>
  </div>
</motion.div>

    </div>



 </div>
  )
}

export default Hero