import React from 'react'
import { useState } from 'react'
import './Header.css'
import logo from '../../assets/logo.png'
import Bars from '../../assets/bars.png'
import { Link } from 'react-scroll'

const Header = () => {
  const mobile=window.innerWidth<=768?true:false;
const [openedmenu,setopenedmenu]=useState(false)
  return (
    <div className="header">
      <img src={logo} alt="" className='logo' /> 
{openedmenu===false && mobile=== true?(
  <div
  style={{backgroundColor:"var(--appColor)",padding:'0.5em',borderRadius:'5px'}}
  onClick={()=>setopenedmenu(true)}
  >

    <img  src={Bars} style={{width:'1.5em',height:'1.5em'}} alt="" />
    </div>


):(
<ul className='list'>
<li ><Link onClick={()=>setopenedmenu(false)} to="home" smooth={true} span={true}>home</Link></li>
<li><Link onClick={()=>setopenedmenu(false)} to="programs" smooth={true} span={true}>programs</Link></li>
<li ><Link onClick={()=>setopenedmenu(false)} to="raisons" smooth={true} span={true}>why us</Link></li>
<li><Link onClick={()=>setopenedmenu(false)} to="plans" smooth={true} span={true}>Plans</Link></li>

<li ><Link onClick={()=>setopenedmenu(false)} to="testimonials" smooth={true} span={true}>Testimoniales</Link></li>


</ul>
)}


   
  </div>
  )
}

export default Header