import React from 'react'
import { useState } from 'react'
import './Testimonials.css'
import {testimonialsData} from '../../data/testimonialsData'
import rightArrow from '../../assets/rightArrow.png'
import leftArrow from '../../assets/leftArrow.png'
import {motion} from 'framer-motion'
const Testimonials = () => {
    const transition={type:'spring',duration:3}
    const [index,setindex]=useState(0);

const checknumber=(number)=>{
    if (number>testimonialsData.length-1){
        return 0
    }
    if (number<0){
        return testimonialsData.length-1
    }
    return number
}

const next=()=>{
  setindex((index)=>{
    let newindex=index+1;
    return checknumber(newindex);
  })
}
const prev=()=>{
    setindex((index)=>{
        let newindex=index-1;
        return checknumber(newindex);
    })
}
    
      return (
  <div id='testimonials' className="testimonials">

<div className="l-test">
<span>TESTIMONIALS</span>
<span className='stroke-text'>WHAT THEY</span>
<span>SAY ABOUT US</span>

<motion.div className="reviews"
initial={{opacity:0,x:-100}}
animate={{opacity:1,x:0}}
exit={{opacity:0,x:100}}
transition={transition}>
    {testimonialsData[index].review}
    </motion.div>
<span className='index-span'>
<span>{testimonialsData[index].name}  </span>
<span>- {testimonialsData[index].status}</span>
</span>
</div>

<div className="r-test">
    <motion.div 
    initial={{opacity:0,x:-100}}
    transition={{...transition,duration:2}}
    whileInView={{opacity:1,x:0}}
    >
    </motion.div>
    <motion.div
    initial={{opacity:0,x:100}}
    transition={{...transition,duration:2}}
    whileInView={{opacity:1,x:0}}
    >.</motion.div>

<motion.img 
transition={transition}
initial={{opacity:0,x:100}}
animate={{opacity:1,x:0}}
exit={{opacity:0,x:-100}}
 src={testimonialsData[index].image} alt="" />
<div className="arrows">
    <img onClick={prev}  src={leftArrow} alt="" />
    <img onClick={next} src={rightArrow} alt="" />

</div>
</div>
  </div>
  )
}

export default Testimonials