import React from 'react'
import './Plans.css'
import {plansData} from '../../data/plansData'
import whiteTick from '../../assets/whiteTick.png'
const Plans = () => {
  return (
<div id='plans' className="plans">
<div className="blur blur-a"></div>
      <div className="blur blur-c"></div>
<div className="plan-header">
    <span className='stroke-text'>READY TO START </span>
    <span>YOUR JOURNEY </span>
    <span className='stroke-text'>NOW WITH US</span>
</div>


<div className="plansdata">
    {plansData.map((plan)=>(
        <div className="plan">
{plan.icon}
<span>{plan.name}</span>
<span>{plan.price}$</span>
<div className="features">
{plan.features.map((feature)=>(
    <div className="feature">
        <img src={whiteTick} alt="" />
        <span>{feature}</span>
        
    </div>
    
))}

</div>
<span className='marg'>See more benefits</span>
<button className='button'>JOIN NOW</button>


        </div>
    ))}
</div>

</div>
  )
}

export default Plans