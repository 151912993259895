import React from 'react'
import './Raisons.css'
import image1 from '../../assets/image1.png'
import image2 from '../../assets/image2.png'
import image3 from '../../assets/image3.png'
import image4 from '../../assets/image4.png'
import tick from '../../assets/tick.png'
import nike from '../../assets/nike.png'
import nb from '../../assets/nb.png'
import adidas from '../../assets/adidas.png'



const Raisons = () => {
  return (
   <div className="raisons" id='raisons'>

<div className="l-raisons">
<img src={image1} alt="" />
<div className='images' >
    <img className='image2' src={image2} alt="" />
    <div className="image-3-4">
        <img src={image3} alt="" />
        <img src={image4} alt="" />

    </div>
</div>

</div>

<div className="r-raisons">

<span>SOME RAISONS</span>
<div className="why">
    <span className='stroke-text'>WHY </span>
    <span>CHOOSE US?</span>
</div>



<div className="img-span">
   <div>
          <img src={tick} alt="" />
          <span>OVER 140+ EXPERT COACHS</span>
   </div>

    <div >
          <img src={tick} alt="" />
          <span>TRAIN SMARTER AND FASTER THAN BEFORE</span>
    </div>
    <div >
         <img src={tick} alt="" />
        <span>1 FREE PROGRAM FOR NEW MEMBERS</span>
    </div>
    <div >
         <img src={tick} alt="" />
        <span>RELIABME PARTNERS</span>
    </div>

</div>

<div className="our-partners">
    <span>OUR PARTNERS</span>
    <div className="partners">
        <img src={adidas} alt="" />
        <img src={nb} alt="" />
        <img src={nike} alt="" />

    </div>
</div>

</div>

   </div>

  )
}

export default Raisons